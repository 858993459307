// ////////////////////////////スマホ/タブレット判別
var _ua = (function (u) {
  return {
    Tablet: (u.indexOf('windows') != -1 && u.indexOf('touch') != -1)
      || u.indexOf('ipad') != -1
      || (u.indexOf('android') != -1 && u.indexOf('mobile') == -1)
      || (u.indexOf('firefox') != -1 && u.indexOf('tablet') != -1)
      || u.indexOf('kindle') != -1
      || u.indexOf('silk') != -1
      || u.indexOf('playbook') != -1,
    Mobile: (u.indexOf('windows') != -1 && u.indexOf('phone') != -1)
      || u.indexOf('iphone') != -1
      || u.indexOf('ipod') != -1
      || (u.indexOf('android') != -1 && u.indexOf('mobile') != -1)
      || (u.indexOf('firefox') != -1 && u.indexOf('mobile') != -1)
      || u.indexOf('blackberry') != -1
  };
})(window.navigator.userAgent.toLowerCase());
// if(_ua.Mobile){}

var osVer;
osVer = 'Android';

// if (navigator.userAgent.indexOf(osVer)>0){
// }

var breakNum = 600;
var tabletNum = 1024;
// ////////////////////////////////////init
$(function () {
  setScroll();
  setHeader();
  // killClick()
  if (!$('#wrapper').hasClass('home')) {}
  // ///////////
  if (_ua.Tablet || _ua.Mobile) {} else {}
  // ///////////
  if (!_ua.Mobile) {}
});

$(window).on('load', function () {
  heightLineGroup();
  svg4everybody();
  setLoaded();
  judgeWinSize();
  setAcc();
  setWay();
  setMainMenu();
  setEvClass();
  startScroll();
  setEcForce();
  // setScrollTop()
  // setTextile()
  if ($('#wrapper').hasClass('home')) {} else {}
  if (!_ua.Mobile && !_ua.Tablet) {
    setPal();
  } else {}
});

// ////////////////////ECforceDom調整
function setEcForce () {
  $('.col-md-12>*').unwrap().unwrap().unwrap().removeClass('col-md-12');

  var disableFileName = 'base-a8fbc39cbb041e6b133f6a274d6f26ab92073160a1339a8bef899dc62437b67e.css'; // 無効にするcssのファイル名
  for (var i = 0; i < document.styleSheets.length; i++) {
    // ファイルパス（フルパス）
    var styleSheetPath = document.styleSheets[i].href;
    // console.log(styleSheetPath)
    // フルパスからファイル名を抽出
    // console.log(fileName, disableFileName)
    // console.log(fileName)
    if (styleSheetPath) {
      var fileName = styleSheetPath.substring(styleSheetPath.lastIndexOf('/') + 1, styleSheetPath.length);
      if (fileName === disableFileName) {
        // 無効にするファイル名なので無効にする
        document.styleSheets[i].disabled = true;
      // console.log(document.styleSheets[i].href)
      }
    }
  }
// alert('aaa')
}

// ////////////////////動画制御
function topVideo () {
  $('.topMovie').each(function () {
    var target = $(this).find('.video');
    var video = target.get(0);
    video.play();
  });
// function playVideos (videos) {
//   var startPosition = $(window).scrollTop() + $(window).height()
//   videos.each(function (index) {
//     if (startPosition > $(this).offset().top) {
//       $(this).get(0).play()
//     }
//   })
// }
// $(window).on('load', function () {
//   var videos = $('.topMovie video')
//   if (videos.length) {
//     playVideos(videos)
//     $(window).on('scroll', function () {
//       playVideos(videos)
//     })
//   }
// })
}
// ////////////////////動画制御
function setVideo () {
  $('.movieWrap').each(function () {
    var target = $(this).find('.video');
    var video = target.get(0);
    var playBtn = $(this).find('.playBtn');
    var summary = $(this).parents('.movieSec').find('.videoSummary');
    playBtn.on('click', function () {
      video.play();
      video.setAttribute('controls', 'controls');
      target.addClass('stand');
      $(this).addClass('hide');
      summary.addClass('hide');
    });
    video.addEventListener('playing', function () {
      playBtn.addClass('hide');
      summary.addClass('hide');
    }, true);
    video.addEventListener('pause', function () {
      playBtn.removeClass('hide');
      summary.removeClass('hide');
    }, true);
    video.addEventListener('ended', function () {
      playBtn.removeClass('hide');
      summary.removeClass('hide');
    }, true);
    // video.addEventListener('canplaythrough', function () {
    //  playBtn.removeClass('hide')
    //  summary.removeClass('active')
    //  startScroll()
    // }, true)
    target.mouseover(
      function () {
        if (target.hasClass('stand')) {
          video.setAttribute('controls', 'controls');
        // summary.addClass('active')
        }
      }).mouseout(
      function () {
        if (target.hasClass('stand')) {
          video.setAttribute('controls', 'controls');
        // summary.removeClass('active')
        }
      }
    );
  });
}

// //////////////////イベントクラス制御
function setEvClass () {
  $('.brightBtn').hover(
    function () {
      $(this).parents('.brightCont').addClass('isOver');
    },
    function () {
      $(this).parents('.brightCont').removeClass('isOver');
    }
  );
}

// //////////////////TOP背景効果
function setBgEffect () {
  var endPoint = $('#cont_04').offset().top - 200;

  $('.bgEffect').each(function (i, elem) {
    var one = $(elem).offset().top;
    $(window).on('load scroll resize', function () {
      var two = $(window).height();
      // var two = 0
      // var two = 0
      var three = $(window).scrollTop();
      var showClass = 'show';
      var timing = 0; // 50px, add to css
      if (three >= one - two + timing) {
        $(elem).addClass(showClass);
      } else {
        $(elem).removeClass(showClass);
      }
      if (three > endPoint) {
        $(elem).addClass('hide');
      }else {
        $(elem).removeClass('hide');
      }
    });
  });
}

// ///////Topメインビジュアル
function setMainVis () {
  $('#mainVis .photos>*').each(function () {
    var imgCount = $(this).find('.photo').length;
    var target = $(this);
    $(this).find('.photo:nth-child(1) .mask').addClass('slide');
    setInterval(function () {
      setSlide(target);
    }, 11000);
  });

  function setSlide (target) {
    target.find('.photo:nth-child(2) .mask').addClass('slide');
    setTimeout(function () {
      target.find('.photo:nth-child(1) .mask').removeClass('slide');
      target.find('.photo:nth-child(1)').appendTo(target);
    }, 2000);
  }
}

// ///////////////////////////アコーディオン制御
function setAcc () {
  $('.accordionLink').on('click', function () {
    $(this).addClass('active');
    $(this).parents('article').find('.accordionCont').slideDown('fast');
  });
  $('.addToggle dt').on('click', function () {
    $(this).toggleClass('active');
    $(this).next().slideToggle('fast');
  });
}

// ///////////////////////////慣性スクロール パララックス
function setPal () {
  $('.pal').removeClass('pal');
  $('#wrapper').addClass('setLuxy');
  luxy.init({
    wrapper: '#outerMenu',
    // targets: '.pal',
    // wrapperSpeed: 0.08
    wrapperSpeed: 0.2
  });
/*$.scrollify({
  section: '.scrollifySec'
});*/
}

// //////////////////////////メインメニュー
var current_scrollY;
function setMainMenu () {
  $('#burgerMenu').on('click', function () {
    if (!$(this).hasClass('active')) {
      openFnc();
    }else {
      closeFnc();
    }
  });

  $('#clickBlocker').on('click', function () {
    closeFnc();
  });

  var winW = $(window).width();
  var timer = false;
  var currentWidth = window.innerWidth;
  window.addEventListener('resize', function () {
    winW = $(window).width();
    if (currentWidth == window.innerWidth) {
      return;
    }
    currentWidth = window.innerWidth;
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      if (winW > tabletNum) {
        closeFnc();
      }
    }, 200);
  });
}

function openFnc () {
  if ($('#wrapper').hasClass('fixedHeader')) {
    $('#wrapper').addClass('newsFlag');
  }else {
    $('#wrapper').removeClass('newsFlag');
  }
  current_scrollY = $(window).scrollTop();
  $('body').addClass('breakH');
  $('#burgerMenu').addClass('active');
  $('#wrapper').addClass('menuOpen');
  $('#outerMenu').css('top', -(current_scrollY));
  setTimeout(function () {
    $('html, body').prop({
      scrollTop: 0
    });
  }, 100);
}

function closeFnc () {
  $('body').removeClass('breakH');
  $('#wrapper').addClass('breakLuxy');
  $('#burgerMenu').removeClass('active');
  $('#outerMenu').css('top', '');
  $('#wrapper').removeClass('menuOpen');
  $('html, body').prop({
    scrollTop: current_scrollY
  });
  setTimeout(function () {
    $('#wrapper').removeClass('breakLuxy');
  }, 800);
}

// ///////ヘッダー制御
function setHeader () {
  var timer = false;
  $(window).on('resize', function () {
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      $('header').removeClass('fixedHeader');
    }, 10);
  });

  fixedHeader();

  $(window).scroll(function () {
    fixedHeader();
  });

  function fixedHeader () {
    if ($('#wrapper').hasClass('home')) {
      h = $('header').height();
    }else {
      h = $('header').height();
    }
    baseHeight = h;
    if ($(this).scrollTop() <= baseHeight) {
      $('#wrapper').removeClass('fixedHeader');
    }else if ($(this).scrollTop() > baseHeight) {
      $('#wrapper').addClass('fixedHeader');
    }
  }
}

// ////////////ウィンドウサイズを判別
function judgeWinSize () {
  var winW = $(window).width();
  if (winW > breakNum) {
    $('#wrapper').addClass('setPc');
  } else {
    $('#wrapper').addClass('setSp');
  }

  var timer = false;
  var currentWidth = window.innerWidth;
  window.addEventListener('resize', function () {
    if (currentWidth == window.innerWidth) {
      return;
    }
    currentWidth = window.innerWidth;
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      winW = $(window).width();
      if (winW > breakNum) {
        $('#wrapper').addClass('setPc');
        $('#wrapper').removeClass('setSp');
      } else {
        $('#wrapper').addClass('setSp');
        $('#wrapper').removeClass('setPc');
      }
    }, 200);
  });
}

// //////////////////////////////ロード完了
function setLoaded () {
  $('#loading').addClass('loaded');
  $('#wrapper').addClass('loaded');
  $('body,html').animate({
    scrollTop: 0
  }, 0, 'swing');
}

// ///////waypoint
function setWay () {
  $('.ttlEffect,.way,.filterWay,.alphaWay,.setAnimation,#bgTx>div,.nullWay').waypoint(function (direction) {
    var activePoint = $(this.element);
    if (direction === 'down') { // scroll down
      activePoint.addClass('active');
    }
  //    else{ //scroll up
  //        activePoint.removeClass('active')
  //    }
  }, {
    offset: '90%'
  });

  $('.shuffle').waypoint(function (direction) {
    var activePoint = $(this.element);
    if (direction === 'down') { // scroll down
      activePoint.addClass('active');
      // var container = $(this.element)
      activePoint.shuffleLetters();
    }
  //    else{ //scroll up
  //        activePoint.removeClass('active')
  //    }
  }, {
    offset: '70%'
  });

  $('.scaleway,.nullWay,.fade_01,.fade_02').waypoint(function (direction) {
    var activePoint = $(this.element);
    if (direction === 'down') { // scroll down
      activePoint.addClass('active');
    }
  //    else{ //scroll up
  //        activePoint.removeClass('active')
  //    }
  }, {
    offset: '70%'
  });
}

// /////////////////////////////スムーススクロール
function setScroll () {
  $('a[href^="#"]').click(function () {
    if (!$(this).hasClass('crNav')) {
      // スクロールの速度
      var speed = 400; // ミリ秒
      // アンカーの値取得
      var href = $(this).attr('href');
      // 移動先を取得
      var target = $(href == '#' || href == '' ? 'html' : href);
      // 移動先を数値で取得
      if (!_ua.Mobile) {
        var position = target.offset().top - $('header').height();
      } else {
        var position = target.offset().top - $('header').height();
      }
      if ($(this).hasClass('unqNav')) {
        // current_scrollY = position
        closeFnc();
        var moveTo = target.offset().top;
        // console.log(moveTo)
        $('body,html').animate({
          scrollTop: moveTo
        }, speed, 'swing');
        return false;
      } else {
        // スムーススクロール
        $('body,html').animate({
          scrollTop: position
        }, speed, 'swing');
        return false;
      }
    }
  });
}
function startScroll () {
  setMrg = $('header').height();
  var timer = false;
  if (timer !== false) {
    clearTimeout(timer);
  }
  setTimeout(function () {
    var hashID = $(location).attr('hash');
    if (hashID) {
      var winW = $(window).width();
      if (winW > breakNum) {
        unqPc();
      } else {
        unqPc();
      }

      var timer = false;
      var currentWidth = window.innerWidth;
      window.addEventListener('resize', function () {
        if (currentWidth == window.innerWidth) {
          return;
        }
        currentWidth = window.innerWidth;
        if (timer !== false) {
          clearTimeout(timer);
        }
        timer = setTimeout(function () {
          winW = $(window).width();
          if (winW > breakNum) {
            unqPc();
          } else {}
        }, 200);
      });

      function unqPc () {
        var speed = 400; // ミリ秒
        var href = hashID;
        var target = $(href == '#' || href == '' ? 'html' : href);
        var position = target.offset().top - setMrg;
        // console.log(position)
        $('body,html').animate({
          scrollTop: position
        }, speed, 'swing');
      }
    }
  }, 500);
}

// ////////////////////////////////////ランドスケープ判定
var isLandscape = function () {
  if (window.innerHeight > window.innerWidth) {
    jQuery('body').addClass('portrait');
    jQuery('body').removeClass('landscape');
  } else {
    jQuery('body').addClass('landscape');
    jQuery('body').removeClass('portrait');
  }
};

if (_ua.Mobile) {
  jQuery(window).resize(function () {
    isLandscape();
  });
  isLandscape();
}

// //////////////////////////////高さ揃え
function heightLineGroup () {
  // setAutoHeight('#photoDiary .articleStyle_02 .inner')
  var winW = $(window).width();
  if (winW > breakNum) {
    // setAutoHeight('#photoDiary .articleStyle_02 .inner')
    // setAutoHeight(".setH_01 .summary",3)
  } else {
    // setAutoHeight("#setH_08 h2",2)
  }

  startResize();
}

function setAutoHeight (target, count) {
  $(target).tile(count);
}

function startResize () {
  var timer = false;
  var currentWidth = window.innerWidth;
  window.addEventListener('resize', function () {
    if (currentWidth == window.innerWidth) {
      return;
    }
    currentWidth = window.innerWidth;
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      heightLineGroup();
    }, 200);
  });
}
